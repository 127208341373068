// Code generated by slopbucket cdk package update script
// Last updated: Wed, 23 Jun 2021 04:26:54 GMT

export const AMPLIFY_CONFIG = {
  "Auth": {
    "region": "us-east-2",
    "userPoolId": "us-east-2_kX41pv0AK",
    "userPoolWebClientId": "2gkjtta86mhvf9ktke6v79uhs0"
  },
  "API": {
    "endpoints": [
      {
        "name": "SlopApi",
        "endpoint": "https://evwhg3smef.execute-api.us-east-2.amazonaws.com/prod/"
      }
    ]
  }
}
