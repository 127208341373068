import React, { FunctionComponent, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { Container, Nav } from 'react-bootstrap'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { MaxStat, Member, Rating } from '../shared'

const pallet = ['#0597f2', '#460273', '#f2d16d', '#f27d16']
const selfColor = '#d92949'

type MemberOverviewProps = {
  member: Member
}

type RatingMode = 'average' | 'all'

type MemberRatingsChartProps = {
  member: Member
  mode: RatingMode
}

const collapseRatings = (ratings: Rating[]): [any[], Array<[string, string]>] => {
  const stats: Map<string, [string, number][]> = new Map<string, [string, number][]>()
  for (const { from, rating } of ratings) {
    for (const { name, value } of rating) {
      if (stats.has(name)) {
        stats.get(name)?.push([from, value])
      } else {
        stats.set(name, [[from, value]])
      }
    }
  }

  const series = new Set<string>()
  const collapsed: any[] = []
  stats.forEach((ratings, statName) => {
    let ratingTotal = 0
    const statData: any = { statName }
    for (const [rater, value] of ratings) {
      ratingTotal += value
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      statData[rater] = value
      series.add(rater)
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    statData.average = ratingTotal / ratings.length
    collapsed.push(statData)
  })

  return [
    collapsed,
    Array.from(series)
      .sort()
      .map((seriesName, index) => [seriesName, pallet[index % pallet.length]]),
  ]
}

const MemberRatingsChart: FunctionComponent<MemberRatingsChartProps> = ({ member, mode }) => {
  const [statsData, series] = collapseRatings(member.ratings)
  return (
    <ResponsiveContainer width="60%" aspect={1}>
      <BarChart
        width={500}
        height={300}
        data={statsData}
        layout="vertical"
        margin={{ top: 20, right: 5, left: 10, bottom: 10 }}
      >
        <XAxis
          type="number"
          domain={[0, MaxStat]}
          ticks={Array(MaxStat + 1)
            .fill(0)
            .map((_, index) => index)}
          max={MaxStat}
        />
        <YAxis dataKey="statName" type="category" />
        <Tooltip />
        <CartesianGrid />
        {/* <Bar dataKey={member.name} fill={selfColor} background={{ fill: '#eee' }} name="Self" /> */}
        <Bar dataKey="average" fill={pallet[0]} background={{ fill: '#eee' }} hide={mode === 'all'} name="Average" />
        {series.map(([seriesName, seriesColor]) => (
          <Bar
            key={seriesName}
            dataKey={seriesName}
            fill={seriesName === member.name ? selfColor : seriesColor}
            background={{ fill: '#eee' }}
            hide={mode === 'average'}
          />
        ))}
        <Legend />
      </BarChart>
    </ResponsiveContainer>
  )
}

export const MemberOverview: FunctionComponent<MemberOverviewProps> = ({ member }) => {
  const [mode, setMode] = useState<RatingMode>('average')
  return (
    <Container>
      <h2>
        {member.name}{' '}
        <Badge hidden={!member.isYou} variant="info">
          (you)
        </Badge>
      </h2>
      <h3>
        <i>{member.nickname}</i>
      </h3>
      {member.ratings.length > 0 ? (
        <div>
          <MemberRatingsChart member={member} mode={mode} />
          <Nav variant="pills" defaultActiveKey={mode}>
            <Nav.Item>
              <Nav.Link eventKey={'average'} onSelect={() => setMode('average')}>
                Average
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={'all'} onSelect={() => setMode('all')}>
                All Ratings
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      ) : (
        <p>No ratings yet for user</p>
      )}
    </Container>
  )
}
