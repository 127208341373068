type Stat = {
  name: string
  value: number
}

export type Rating = {
  from: string
  rating: Stat[]
}

export type Member = {
  id: string
  name: string
  nickname: string
  ratings: Rating[]
  isYou: boolean
}

export const MaxStat = 5
