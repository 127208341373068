import React, { useEffect, useState } from 'react'
import './App.css'

import { API, Auth } from 'aws-amplify'
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import { HashRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap'
import { MemberList } from './components/member-list'
import { Member } from './shared'
import { SLOP_API } from './amplify-config'

const App = () => (
  <Router>
    <div className="App">
      <header>
        <Navbar bg="dark" expand="lg" variant="dark">
          <Navbar.Brand>Slop Bucket</Navbar.Brand>
          <Navbar.Collapse>
            <Nav>
              <Nav.Link as={Link} to="/power">
                Power Levels
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <AmplifySignOut />
        </Navbar>
      </header>
      <Switch>
        <Route path="/power">
          <PowerLevels />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  </Router>
)

const Home = () => <Redirect to="/power" />

const PowerLevels = () => {
  const [serverMembers, setServerMembers] = useState<Member[]>([])

  useEffect(() => {
    void (async () => {
      type PowerResponse = {
        members: Member[]
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data: PowerResponse = await API.get(SLOP_API, 'power', {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      })

      setServerMembers(data.members)
    })()
  }, [])

  return (
    <div>
      <h1>Power Levels</h1>
      <MemberList members={serverMembers} />
    </div>
  )
}

export default withAuthenticator(App)
