import React, { FunctionComponent } from 'react'
import { Badge, Col, Nav, Row } from 'react-bootstrap'
import { Link, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Member } from '../shared'
import { MemberOverview } from './member-overview'

export type MemberListProps = {
  members: Member[]
}

export const MemberList: FunctionComponent<MemberListProps> = ({ members }: MemberListProps) => {
  const sortedMembers = members.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))

  const match = useRouteMatch()
  const you: Member | undefined = sortedMembers.filter((member) => member.isYou)[0]

  return (
    <Switch>
      <Route path={`${match.url}/:memberId`}>
        <FullMemberList members={sortedMembers} rootRoute={match.path} />
      </Route>
      <Route path={match.url}>
        <Redirect to={`${match.url}/${you?.id || sortedMembers[0]?.id || 'error'}`} />
      </Route>
    </Switch>
  )
}

type FullMemberListProps = {
  members: Member[]
  rootRoute: string
}

type MemberListParams = {
  memberId: string
}

const FullMemberList: FunctionComponent<FullMemberListProps> = ({ members, rootRoute }: FullMemberListProps) => {
  const { memberId } = useParams<MemberListParams>()

  const maybeActiveMembers = members.filter((member) => member.id === memberId)
  let activeMember: Member | undefined
  if (maybeActiveMembers.length !== 1) {
    console.log(`Error: didnt find user with id "${memberId}"`)
    const maybeYouMembers = members.filter((member) => member.isYou)
    if (maybeYouMembers.length !== 1) {
      console.log(`Error: didnt find only 1 user with id "${memberId}" or active user`)
      activeMember = members[0]
    } else {
      activeMember = maybeYouMembers[0]
    }
  } else {
    activeMember = maybeActiveMembers[0]
  }

  if (activeMember === undefined) {
    return <p>User not found!</p>
  }

  return (
    <Row>
      <Col sm={3}>
        <Nav variant="pills" className="flex-column" defaultActiveKey={activeMember.id}>
          {members.map((member) => (
            <Nav.Item key={member.id}>
              <Nav.Link as={Link} to={`${rootRoute}/${member.id}`} eventKey={member.id}>
                {member.name}{' '}
                <Badge hidden={!member.isYou} variant="info">
                  (you)
                </Badge>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Col>
      <Col sm={9}>
        <MemberOverview member={activeMember} />
      </Col>
    </Row>
  )
}
